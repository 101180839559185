<template>
    <!-- Component: organisms/my-bookmarks-card--contact -->
    <info-card :contact="contact">
        <div class="card-content">
            <div class="section">
                <div class="content">
                    <p class="card__info__label">Contact Name</p>

                    <span class="contact-is-leader" v-if="contact.agencyHead">
                        <h1 class="card__info__title">
                            <a :href="contact.url">
                                <span class="name">{{ fullNameText }}</span>
                                <leadership-marker/>
                            </a>
                        </h1>
                    </span>
                    <h1 class="card__info__title" v-else>
                        <a :href="contact.url">{{ fullNameText }}</a>
                    </h1>

                    <p class="card__info__label" v-if="position?.agency">Agency</p>
                    <tag-agency
                        :tag-text="position.agency.AgencyRef.name"
                        :tag-url="position.agency.AgencyRef.url"
                        v-if="position?.agency.AgencyRef"
                    />

                    <p class="card__info__label" v-if="position">Position</p>
                    <tag-role
                        :tag-text="position.name + (contact.positionRole ? ' (' + contact.positionRole + ')' : '')"
                        :tag-url="position.url"
                        v-if="position"
                    />
                </div>

                <div class="media" v-if="contact.photo?.url">
                    <div class="">
                        <figure class="image">
                            <img :src="contact.photo.url" :alt="contact.photo.alt">
                        </figure>
                    </div>
                </div>
            </div>
        </div>
    </info-card>
    <!-- End Component: organisms/my-bookmarks-card--contact -->
</template>

<script>

    import InfoCard from "./info-card";
    import LeadershipMarker from "./leadership-marker";
    import TagAgency from "./tag--agency";
    import TagRole from "./tag--role";
    import { computed } from "vue";
    import { capitalize } from "@/helpers/filter";
    import { contactFullName } from "@/helpers/objects";

    export default {
        name: 'MyBookmarksCardContact',
        components: {
            TagRole,
            TagAgency,
            LeadershipMarker,
            InfoCard
        },
        props: ['bookmark'],
        setup(props) {
            const fullNameText = computed(() => contactFullName(props.bookmark.contact.ContactRef));

            const contact = computed(() => props.bookmark && props.bookmark.contact && props.bookmark.contact.ContactRef);
            const position = computed(() => props.bookmark
                && props.bookmark.contact
                && props.bookmark.contact.ContactRef.position
                && props.bookmark.contact.ContactRef.position.PositionRef
            );

            return {fullNameText, contact, position}
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    .card__info__label {
        padding-bottom: 8px;
        padding-top: 8px;
    }
    .card__info__title {
        display: flex;
        span.name {
            float: left;
        }
    }
</style>
