<template>
    <!-- Page: pages/my-bookmarks -->

    <br/>

    <breadcrumbs :items="path"/>

    <br/>

    <div class="container dfw">
        <h1>My Bookmarks</h1>
<!--        <user-tools/>-->
    </div>

    <general-container>
        <section>
            <div class="content-block__content">
                <div class="bookmarks-filter">
                    Filter by
                    <span @click.prevent="filterByType('contact')" :class="{'active': typeFilter === 'contact'}"
                          title="Filter by Contact"
                    >
                        <tag-contact :tag-text="'Contact'"></tag-contact>
                    </span>
                    <span @click.prevent="filterByType('position')" :class="{'active': typeFilter === 'position'}"
                          title="Filter by Position"
                    >
                        <tag-role :tag-text="'Position'"></tag-role>
                    </span>
                    <span @click.prevent="filterByType('agency')" :class="{'active': typeFilter === 'agency'}"
                          title="Filter by Agency"
                    >
                        <tag-agency :tag-text="'Agency'"></tag-agency>
                    </span>
                    <span @click.prevent="filterByType('budget')" :class="{'active': typeFilter === 'budget'}"
                          title="Filter by Program"
                    >
                        <tag-budget :tag-text="'Program'"></tag-budget>
                    </span>
                    <span @click.prevent="filterByType('vendor')" :class="{'active': typeFilter === 'vendor'}"
                          title="Filter by Vendor"
                    >
                        <tag-vendor :tag-text="'Vendor'"></tag-vendor>
                    </span>
                    <span @click.prevent="filterByType('contract')" :class="{'active': typeFilter === 'contract'}"
                          title="Filter by Contract"
                    >
                        <tag-contract :tag-text="'Contract'"></tag-contract>
                    </span>
                    <span @click.prevent="filterByType('procurement')" :class="{'active': typeFilter === 'procurement'}"
                          title="Filter by Procurement"
                    >
                        <tag-procurement :tag-text="'Procurement'"></tag-procurement>
                    </span>
                </div>
            </div>
        </section>

        <preloader v-if="bookmarksLoading"></preloader>

        <section>
            <template v-for="bookmark in bookmarks">
                <my-bookmarks-card-contact
                    v-if="bookmark.type === 'contact' && bookmark.contact"
                    :bookmark="bookmark"
                ></my-bookmarks-card-contact>

                <my-bookmarks-card-position
                    v-if="bookmark.type === 'position' && bookmark.position"
                    :bookmark="bookmark"
                ></my-bookmarks-card-position>

                <my-bookmarks-card-agency
                    v-if="bookmark.type === 'agency' && bookmark.agency"
                    :bookmark="bookmark"
                ></my-bookmarks-card-agency>

                <my-bookmarks-card-program
                    v-if="bookmark.type === 'budget' && bookmark.budget"
                    :bookmark="bookmark"
                ></my-bookmarks-card-program>

                <my-bookmarks-card-vendor
                    v-if="bookmark.type === 'vendor' && bookmark.vendor"
                    :bookmark="bookmark"
                ></my-bookmarks-card-vendor>

                <my-bookmarks-card-contract
                    v-if="bookmark.type === 'contract' && bookmark.contract"
                    :bookmark="bookmark"
                ></my-bookmarks-card-contract>

                <my-bookmarks-card-procurement
                    v-if="bookmark.type === 'procurement' && bookmark.procurement"
                    :bookmark="bookmark"
                ></my-bookmarks-card-procurement>
            </template>
            <p v-if="!bookmarksLoading && bookmarks.length == 0 && typeFilter">
                Looks like you don't have any bookmarked {{ pluralizeType(typeFilter) }}.
            </p>
            <p v-if="!bookmarksLoading && bookmarks.length == 0 && !typeFilter">
                Looks like you don't have any bookmarks.
            </p>
        </section>

        <pagination :current="page" :page-count="pageCount" v-on:paginated="paginate($event)" v-if="pageCount > 1"/>
    </general-container>
    <!-- End Page: pages/my-bookmarks -->
</template>

<script>
    import RequestHandler from "@/handler/RequestHandler";
    import Breadcrumbs from "./breadcrumbs.vue";
    import UserTools from "@/alivue/components/user-tools";
    import GeneralContainer from "./general-container.vue";
    import SearchBlock from "./search-block.vue";
    import Pagination from "./pagination.vue";
    import InfoCard from "./info-card";
    import { useRoute } from "vue-router";
    import { computed, onMounted, ref } from "vue";
    import MyBookmarksCardContact from "./my-bookmarks-card--contact";
    import MyBookmarksCardPosition from "./my-bookmarks-card--position";
    import MyBookmarksCardAgency from "./my-bookmarks-card--agency";
    import MyBookmarksCardProgram from "./my-bookmarks-card--program";
    import MyBookmarksCardVendor from "./my-bookmarks-card--vendor";
    import MyBookmarksCardContract from "./my-bookmarks-card--contract";
    import MyBookmarksCardProcurement from "./my-bookmarks-card--procurement";
    import TagContact from "./tag--contact";
    import TagRole from "./tag--role";
    import TagBudget from "./tag--budget";
    import TagAgency from "./tag--agency";
    import TagVendor from "./tag--vendor";
    import TagContract from "./tag--contract";
    import TagProcurement from "./tag--procurement";
    import Preloader from "./preloader";
    import { useStore } from "vuex";

    export default {
        components: {
            Preloader,
            TagAgency,
            TagRole,
            TagBudget,
            TagContact,
            TagVendor,
            TagContract,
            TagProcurement,
            MyBookmarksCardAgency,
            MyBookmarksCardPosition,
            MyBookmarksCardContact,
            MyBookmarksCardProgram,
            MyBookmarksCardVendor,
            MyBookmarksCardContract,
            MyBookmarksCardProcurement,
            InfoCard,
            Breadcrumbs,
            UserTools,
            GeneralContainer,
            SearchBlock,
            Pagination
        },
        methods: {
            pluralizeType(type) {
                if (type === 'agency') {
                    return 'agencies';
                }
                if (type == 'budget') {
                    return 'programs';
                }
                return type + 's';
            },
        },
        setup() {
            const itemsPerPage = 8;

            const route = useRoute();
            const typeFilter = ref('');
            const bookmarksLoading = ref(true);
            const bookmarks = ref([]);
            const page = ref(1);
            const totalBookmarksCount = ref(null);
            const pageCount = ref(1);

            const loadBookmarks = () => {
                bookmarksLoading.value = true;
                bookmarks.value = [];
                RequestHandler.loadBookmarkedObjects(typeFilter.value, itemsPerPage, (page.value - 1) * itemsPerPage)
                    .then(data => {
                        bookmarksLoading.value = false;
                        totalBookmarksCount.value = data.totalCount || 0;
                        bookmarks.value = data.list || [];
                        pageCount.value = data.pageCount || 1;
                    })
                    .catch(error => {
                        console.error(error);
                        bookmarksLoading.value = false;
                    });
            };

            const paginate = (pageTo) => {
                page.value = pageTo;
                loadBookmarks();
            };

            const filterByType = (type) => {
                if (type === typeFilter.value) {
                    type = '';
                }
                typeFilter.value = type;
                page.value = 1;
                loadBookmarks();
            };

            onMounted(() => {
                if (route.query.type) {
                    typeFilter.value = route.query.type;
                }
                loadBookmarks();
            });

            const store = useStore();
            const showSearch = computed(() => store.getters.showSearch);

            return {
                paginate, typeFilter, bookmarksLoading, bookmarks, page, totalBookmarksCount, pageCount,
                filterByType,
                path: [{title: 'My Bookmarks'}],
                showSearch,
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    .bookmarks-filter span {
        opacity: 0.7;

        &.active {
            opacity: 1;
        }
    }
</style>
