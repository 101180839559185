<template>
    <!-- Component: organisms/my-bookmarks-card--program -->
    <info-card :program="budget">
        <div class="card-content">
            <div class="section">
                <div class="content">
                    <p class="card__info__label">Program</p>
                    <h1 class="card__info__title">
                        <a :href="bookmark.url">{{ budget.name }}</a>
                    </h1>

                    <p class="card__info__label" v-if="budget.agency">Agency</p>
                    <tag-agency
                        :tag-text="budget.agency.AgencyRef.name"
                        :tag-url="budget.agency.AgencyRef.url"
                        v-if="budget.agency"
                    />
                </div>
            </div>
        </div>
    </info-card>
    <!-- End Component: organisms/my-bookmarks-card--program -->
</template>

<script>

    import InfoCard from "./info-card";
    import LeadershipMarker from "./leadership-marker";
    import TagAgency from "./tag--agency";
    import TagRole from "./tag--role";
    import { computed } from "vue";

    export default {
        name: 'MyBookmarksCardProgram',
        components: {
            TagRole,
            TagAgency,
            LeadershipMarker,
            InfoCard
        },
        props: ['bookmark'],
        setup(props) {
            const budget = computed(() => props.bookmark && props.bookmark.budget && props.bookmark.budget.ProgramRef);

            return {
                budget
            };
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    .card__info__label {
        padding-bottom: 8px;
        padding-top: 8px;
    }
</style>
