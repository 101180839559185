<template>
    <!-- Component: organisms/my-bookmarks-card--vendor -->
    <info-card :vendor="vendor">
        <div class="card-content">
            <div class="section">
                <div class="content">
                    <p class="card__info__label">
                        <Tooltip :tooltipText="definitions && definitions['Vendor'] && definitions['Vendor'].definition ? definitions['Vendor'].definition : 'Loading...'">
                            <span class='has-tooltip'>
                                Vendor
                            </span>
                        </Tooltip>
                    </p>
                    <h1 class="card__info__title">
                        <a :href="vendor.url">{{ vendor.name }}</a>
                    </h1>

                    <p class="card__info__label" v-if="vendor.factsSecondaryName"
                    >Secondary Name</p>
                    <p class="card__info__data" v-if="vendor.factsSecondaryName"
                    >{{ vendor.factsSecondaryName }}</p>
                </div>

            </div>
        </div>
    </info-card>
    <!-- End Component: organisms/my-bookmarks-card--agency -->
</template>

<script>

    import InfoCard from "./info-card";
    import TagVendor from "./tag--vendor";
    import { computed, ref } from "vue";
    import { useStore } from "vuex";
    import Tooltip from "./tooltip";

    export default {
        name: 'MyBookmarksCardVendor',
        components: {
            TagVendor,
            InfoCard,
            Tooltip
        },
        props: ['bookmark'],
        setup(props) {
            const store = useStore();
            const definitions = computed(() => store.getters.definitions);
            const vendor = computed(() => props.bookmark && props.bookmark.vendor && props.bookmark.vendor.VendorRef);

            return {
                vendor,
                definitions
            };
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    .card__info__label {
        padding-bottom: 8px;
        padding-top: 8px;
    }
</style>
