<template>
    <!-- Component: organisms/my-bookmarks-card--agency -->
    <info-card :agency="agency">
        <div class="card-content">
            <div class="section">
                <div class="content">
                    <p class="card__info__label">Agency</p>
                    <h1 class="card__info__title">
                        <a :href="agency.url">{{ agency.name }}</a>
                    </h1>

                    <p class="card__info__label" v-if="agency.type"
                    >Agency Type</p>
                    <p class="card__info__data" v-if="agency.type"
                    >{{ agency.type }}</p>
                </div>

                <div class="media" v-if="agency.logo">
                    <div class="">
                        <figure class="image">
                            <img :src="agency.logo.url" :alt="agency.logo.alt">
                        </figure>
                    </div>
                </div>
            </div>
        </div>
    </info-card>
    <!-- End Component: organisms/my-bookmarks-card--agency -->
</template>

<script>

    import InfoCard from "./info-card";
    import LeadershipMarker from "./leadership-marker";
    import TagAgency from "./tag--agency";
    import TagRole from "./tag--role";
    import { computed } from "vue";

    export default {
        name: 'MyBookmarksCardAgency',
        components: {
            TagRole,
            TagAgency,
            LeadershipMarker,
            InfoCard
        },
        props: ['bookmark'],
        setup(props) {
            const agency = computed(() => props.bookmark && props.bookmark.agency && props.bookmark.agency.AgencyRef);

            return {
                agency
            };
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    .card__info__label {
        padding-bottom: 8px;
        padding-top: 8px;
    }
</style>
