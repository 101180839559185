<template>
    <!-- Component: organisms/my-bookmarks-card--contract -->
    <info-card :contract="contract">
        <div class="card-content">
            <div class="section">
                <div class="content">
                    <p class="card__info__label">Contract</p>
                    <h1 class="card__info__title">
                        <a :href="contract.url">{{ contract.name }}</a>
                    </h1>

                    <template v-if="contract.vendor">
                        <p class="card__info__label">Vendor</p>
                        <p class="card__info__data"><a :href="contract.vendor.VendorRef.url">
                            {{ contract.vendor.VendorRef.name }}
                        </a></p>
                    </template>

                    <div class="columns">
                        <div class="column" v-if="contract.contractType">
                            <p class="card__info__label">Contract Type</p>
                            <p class="card__info__data">{{ contract.contractType }}</p>
                        </div>

                        <div class="column" v-if="contract.serviceType">
                            <p class="card__info__label">Service Type</p>
                            <p class="card__info__data">{{ contract.serviceType }}</p>
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column" v-if="contract.methodOfProcurement">
                            <p class="card__info__label">Method of Procurement</p>
                            <p class="card__info__data">{{ contract.methodOfProcurement }}</p>
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column" v-if="contract.totalAmount">
                            <p class="card__info__label">Total Amount</p>
                            <p class="card__info__data">${{ priceFormat(contract.totalAmount) }}</p>
                        </div>

                        <div class="column" v-if="contract.orderDate">
                            <p class="card__info__label">Order Date</p>
                            <p class="card__info__data">{{ contract.orderDate }}</p>
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column" v-if="contract.beginDate">
                            <p class="card__info__label">
                                <Tooltip :tooltipText="definitions && definitions['Begin Date'] && definitions['Begin Date'].definition ? definitions['Begin Date'].definition : 'Loading...'">
                                    <span class='has-tooltip'>
                                        Begin date
                                    </span>
                                </Tooltip>
                            </p>
                            <p class="card__info__data">{{ contract.beginDate }}</p>
                        </div>

                        <div class="column" v-if="contract.endDate">
                            <p class="card__info__label">
                                <Tooltip :tooltipText="definitions && definitions['End Date'] && definitions['End Date'].definition ? definitions['End Date'].definition : 'Loading...'">
                                    <span class='has-tooltip'>
                                        End Date
                                    </span>
                                </Tooltip>
                            </p>
                            <p class="card__info__data">{{ contract.endDate }}</p>
                        </div>

                        <div class="column" v-if="contract.newEndDate">
                            <p class="card__info__label">
                                <Tooltip :tooltipText="definitions && definitions['New End Date'] && definitions['New End Date'].definition ? definitions['New End Date'].definition : 'Loading...'">
                                    <span class='has-tooltip'>
                                        New End Date
                                    </span>
                                </Tooltip>
                            </p>
                            <p class="card__info__data">{{ contract.newEndDate }}</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </info-card>
    <!-- End Component: organisms/my-bookmarks-card--agency -->
</template>

<script>

    import InfoCard from "./info-card";
    import LeadershipMarker from "./leadership-marker";
    import TagContract from "./tag--contract";
    import { priceFormat } from "@/helpers/filter";
    import { ref, computed } from "vue";
    import { useStore } from "vuex";
    import Tooltip from "./tooltip";

    export default {
        name: 'MyBookmarksCardContract',
        components: {
            TagContract,
            LeadershipMarker,
            InfoCard,
            Tooltip
        },
        props: ['bookmark'],
        setup(props) {
            const contract = computed(() => props.bookmark && props.bookmark.contract && props.bookmark.contract.ContractRef);
            const store = useStore();
            const definitions = computed(() => store.getters.definitions);

            return {
                contract,
                priceFormat,
                definitions
            };
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    .card__info__label {
        padding-bottom: 8px;
        padding-top: 8px;
    }
</style>
