<template>
    <!-- Component: organisms/my-bookmarks-card--procurement -->
    <info-card :procurement="procurement">
        <div class="card-content">
            <div class="section">
                <div class="content">
                    <p class="card__info__label">Procurement</p>
                    <h1 class="card__info__title">
                        <a @click.prevent="showIframe = true">{{ procurement.title }}</a>
                    </h1>

                    <p class="card__info__label" v-if="procurement?.agency">Agency</p>
                    <tag-agency
                        :tag-text="procurement.agency.AgencyRef.name"
                        :tag-url="procurement.agency.AgencyRef.url"
                        v-if="procurement?.agency.AgencyRef"
                    />

                    <div class="columns">
                        <div class="column" v-if="procurement.status">
                            <p class="card__info__label">Status</p>
                            <p class="card__info__data">{{ procurement.status }}</p>
                        </div>

                        <div class="column" v-if="procurement.adType">
                            <p class="card__info__label">Ad Type</p>
                            <p class="card__info__data">{{ procurement.adType }}</p>
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column" v-if="procurement.startDate">
                            <p class="card__info__label">
                               <span>
                                   Start date
                               </span>
                            </p>
                            <p class="card__info__data">{{ procurement.startDateFormatted }}</p>
                        </div>

                        <div class="column" v-if="procurement.endDate">
                            <p class="card__info__label">
                                 <span>
                                     End Date
                                 </span>
                            </p>
                            <p class="card__info__data">{{ procurement.endDateFormatted }}</p>
                        </div>
                    </div>

                </div>

            </div>
        </div>

        <iframe-modal
            v-if="showIframe"
            @close="showIframe = false"
            @created="newIframeCreated"
            :sourceURL="'https://vendor.myfloridamarketplace.com/search/bids/detail/' + procurement.id"
            :sourceTitle="procurement.title"
            :title="'Bid Details'"
        ></iframe-modal>
    </info-card>
    <!-- End Component: organisms/my-bookmarks-card--procurement -->
</template>

<script>
    import InfoCard from "./info-card";
    import LeadershipMarker from "./leadership-marker";
    import { ref, computed } from "vue";
    import TagAgency from "./tag--agency";
    import IframeModal from "./iframe-modal";

    export default {
        name: 'MyBookmarksCardProcurement',
        components: {
            LeadershipMarker,
            TagAgency,
            InfoCard,
            IframeModal
        },
        props: ['bookmark'],
        setup(props) {
            const procurement = computed(() => props.bookmark && props.bookmark.procurement && props.bookmark.procurement.ProcurementRef);
            const showIframe = ref(false);

            const newIframeCreated = () => {
                showIframe.value = false;
            };

            return {
                procurement,
                newIframeCreated,
                showIframe
            };
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    .card__info__label {
        padding-bottom: 8px;
        padding-top: 8px;
    }
</style>
