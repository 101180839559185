<template>
    <!-- Component: organisms/my-bookmarks-card--position -->
    <info-card :position="position">
        <div class="card-content">

            <div class="section">
                <div class="content">
                    <p class="card__info__label">Position</p>
                    <h1 class="card__info__title">
                        <a :href="position.url">{{ position.name }}</a>
                    </h1>

                    <p class="card__info__label" v-if="position.agency">Agency</p>
                    <tag-agency
                        v-if="position.agency"
                        :tag-text="position.agency?.AgencyRef.name"
                        :tag-url="position.agency?.AgencyRef.url"
                    />
                </div>

                <div class="media" v-if="position.agency?.AgencyRef.logo?.url">
                    <div>
                        <figure class="image">
                            <img :src="position.agency.AgencyRef.logo.url" :alt="position.agency.AgencyRef.logo.alt">
                        </figure>
                    </div>
                </div>
            </div>
        </div>
    </info-card>
    <!-- End Component: organisms/my-bookmarks-card--position -->
</template>

<script>

    import InfoCard from "./info-card";
    import TagAgency from "./tag--agency";
    import { computed } from "vue";
    export default {
        name: 'MyBookmarksCardPosition',
        components: {
            TagAgency,
            InfoCard
        },
        props: ['bookmark'],
        setup(props) {
            const position = computed(() => props.bookmark && props.bookmark.position && props.bookmark.position.PositionRef);

            return {
                position
            };
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    .card__info__label {
        padding-bottom: 8px;
        padding-top: 8px;
    }
</style>
